
















































import { Component, Vue, Watch } from "vue-property-decorator";
import { Articoli } from "@/models/entities/articoli.interface";
import { articoliservice } from "@/services/api/articoli.service";
import { Table } from "@/models/entities/table.interface";
import ReloadModule from "@/store/modules/reload.module";
import store from "@/store/index";
import { getModule } from "vuex-module-decorators";
import { saveExcel } from "@progress/kendo-vue-excel-export";
const reloadModule = getModule(ReloadModule, store);
@Component({
  components: {},
})
export default class Piatti extends Vue {
  public loading = false;
    private table: Table<Articoli> = {
        sortBy: ["qualita", "misura"],
        sortDesc: [false, false],
    search: "",
    headers: [
        { text: "Articolo", value: "articolo", filterable: false, sortable: false },
      { text: "Qualità", value: "qualita" },
      { text: "Misura (mm)", value: "misura", sortable: false },
      {
        text: "Quantità (Kg)",
        value: "quantita",
        sortable: false,
        filterable: false,
        },
        {
            text: "Ultimo aggiornamento",
            value: "ultimoAggiornamentoFile",
            sortable: false,
            filterable: false,
        },
    ],
    items: [],
    };



  private customsort(
    items: Array<Articoli>,
    sortBy: string[],
    sortDesc: boolean[]
  ): Array<Articoli> {
    try {
      if (!sortBy || sortBy.length == 0) {
        return items;
      } else if (sortBy && sortBy.length == 1 && sortBy[0] == "qualita") {
        return items.sort((a, b) => {
          if (a.qualita > b.qualita) {
            return sortDesc[0] ? 1 : -1;
          }
          if (a.qualita < b.qualita) {
            return sortDesc[0] ? -1 : 1;
          }
          if (a.qualita == b.qualita) {
            if (a.misura > b.misura) return 1;
            if (a.misura < b.misura) return -1;
            return 0;
          }
        });
      } else if (sortBy && sortBy.length == 1) {
        return items.sort((a, b) => {
          if (a[sortBy[0]] > b[sortBy[0]]) {
            return sortDesc[0] ? 1 : -1;
          }
          if (a[sortBy[0]] < b[sortBy[0]]) {
            return sortDesc[0] ? -1 : 1;
          }
          if (a[sortBy[0]] == b[sortBy[0]]) {
            return 0;
          }
        });
      }
    } catch {
      console.log("err");
    }
    return items;
  }

  async created(): Promise<void> {
    await this.loadTable();
  }

  get reload(): boolean {
    return reloadModule.status;
  }

  @Watch("reload")
  reloadChanged() {
    if (this.reload) {
      this.loadTable();
    }
  }

  async loadTable(): Promise<void> {
    const r = await articoliservice.readAll("PT");
    this.$set(this.table, "items", r.data);
    reloadModule.hideReload();
  }
  async exportTable() {
    var myData = [];
    var date = new Date();
    var filename =
      date.getDate() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      "_";
    this.table.items.forEach((element) => {
      myData.push({
        Articolo: element.articolo,
        Qualita: element.qualita,
        Misura: element.misura,
        Quantita: element.quantita,
      });
    });
    saveExcel({
      data: myData,
      fileName: filename + "Piatti",
      columns: [
        { field: "Articolo", name: "Articolo" },
        { field: "Qualita", name: "Qualita" },
        { field: "Misura", name: "Misura (mm)" },
        { field: "Quantita", name: "Quantità (Kg)" },
      ],
    });
  }
}
